import React from 'react';

const AboutPage = () => {
  return (
    <>
      <header className="bg-dark page-header has-bg bg-fixed" style={{ backgroundImage: `url(img/home-hero.jpg)` }}>
        <div className="section-overlay"></div>
        <div className="container px-5">
          <div className="row gx-5 align-items-center justify-content-center">
            <div className="col-lg-10">
              <div className="my-5 text-center">
                <h1 className="page-title display-5 fw-thin text-white">About Us</h1>
                <p className="page-description lead fw-normal text-white">
                  Learn more about us, what we do, and how we work.
                </p>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className="py-4" id="features">
        <div className="container px-5 my-5">
          <div className="row mb-5">
            <div className="col-lg-12 mb-5 mb-lg-0">
              <h2 className="fw-thin mb-0">Who We Are</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-10">
              <p className="lead">
                At Vestify Hub, we are more than just consultants. We are visionaries committed to building a better
                future through real estate. With a focus on acquiring, transforming, and managing residential assets, we
                strive to create value for our clients, tenants, and communities.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 bg-light">
        <div className="container px-5 my-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6">
              <img className="img-fluid rounded mb-5 mb-lg-0" src="https://dummyimage.com/600x300/343a40/6c757d" alt="..." />
            </div>
            <div className="col-lg-6">
              <h2 className="fw-thin">Our Mission</h2>
              <p className="lead fw-thin text-muted mb-0">
                To provide exceptional real estate solutions that empower individuals to achieve financial freedom and
                fulfill their life purpose.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container px-5 my-5">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6 order-first order-lg-last">
              <img className="img-fluid rounded mb-5 mb-lg-0" src="https://dummyimage.com/600x300/343a40/6c757d" alt="..." />
            </div>
            <div className="col-lg-6">
              <h2 className="fw-thin">Our Mission</h2>
              <p className="lead fw-thin text-muted mb-0">
                To revolutionize real estate services with an entrepreneurial spirit, driving positive change in the
                industry and beyond.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 bg-light">
        <div className="container px-5 my-5">
          <div className="text-center">
            <h2 className="fw-thin">Our team</h2>
            <p className="lead fw-normal text-muted mb-5">Dedicated to quality and your success</p>
          </div>
          <div className="row gx-5 row-cols-1 row-cols-sm-2 row-cols-xl-4 justify-content-center">
            {/* Example Team Member */}
            <div className="col mb-5 mb-5 mb-xl-0">
              <div className="text-center">
                <img className="img-fluid rounded-circle mb-4 px-4" src="https://dummyimage.com/150x150/ced4da/6c757d" alt="..." />
                <h5 className="fw-thin">Ibbie Eckart</h5>
                <div className="fst-italic text-muted">Founder &amp; CEO</div>
              </div>
            </div>
            {/* Add more team members as required */}
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutPage;
