import React from "react";
import logo from "../img/logo.png"; // Importing the logo image
import homeHero from "../img/home-hero.jpg"; // Importing the hero image
import property1 from "../img/property-1.jpg"; // Importing the property images
import property3 from "../img/property-3.jpg";
import property4 from "../img/property-4.jpg";
import property5 from "../img/property-5.jpg";
import property6 from "../img/property-6.jpg";
import property2 from "../img/property-2.jpg";
// import { BiSearch, BiGem, BiWallet } from 'react-icons/bi';
import ServicesSections from "./ServicesSection";



function HomePage() {
  return (
    <main className="flex-shrink-0">
      {/* Navigation */}
      {/* <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container px-5">
          <div className="navbar-brand">
            <a href="index.html">
              <div className="logo">
                <img src={logo} alt="VestifyHub" />
              </div>
            </a>
          </div>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#!"
                  id="deals"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  About
                </a>
                <ul className="dropdown-menu border-0 shadow bsb-zoomIn" aria-labelledby="deals">
                  <li><a className="dropdown-item" href="about.html">Our Story</a></li>
                  <li><a className="dropdown-item" href="our-offerings.html">Our Offering</a></li>
                  <li><a className="dropdown-item" href="our-principle.html">Our Principles</a></li>
                  <li><a className="dropdown-item" href="how-it-works.html">How Vestify Works</a></li>
                </ul>
              </li>
              <li className="nav-item"><a className="nav-link" href="invest.html">Invest</a></li>
              <li className="nav-item"><a className="nav-link" href="manage.html">Manage</a></li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#!"
                  id="deals"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Deals
                </a>
                <ul className="dropdown-menu border-0 shadow bsb-zoomIn" aria-labelledby="deals">
                  <li><a className="dropdown-item" href="pipeline.html">Pipeline</a></li>
                  <li><a className="dropdown-item" href="development.html">In Development</a></li>
                  <li><a className="dropdown-item" href="cash-flowing.html">Cash Flowing</a></li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="nav-button-wrap">
            <a className="btn btn-primary-alt btn-md px-4 me-sm-3" href="sign-up.html">Sign Up</a>
            <a className="btn btn-primary-line btn-md px-4 me-sm-3" href="login.html">Login</a>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
      </nav> */}

      {/* Header */}
      <header
        className="bg-dark home-hero has-bg bg-fixed"
        style={{ backgroundImage: `url(${homeHero})` }}
      >
        <div className="section-overlay"></div>
        <div className="container px-5">
          <div className="row gx-5 align-items-center justify-content-center">
            <div className="col-lg-8 col-xl-7 col-xxl-6">
              <div className="my-5 text-center text-xl-start">
                <h1 className="hero-title display-5 fw-thin text-white">
                  Build Wealth with
                  <br />
                  Vestify<span className="fw-bolder">Hub</span>
                </h1>
                <p className="hero-description lead fw-normal text-white">
                  Find a deal, Acquire and Earn!
                </p>
                <div className="d-flex gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start mt-5">
                  <a
                    className="btn btn-primary-alt btn-md px-4 me-sm-3"
                    href="#features"
                  >
                    Learn More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-xxl-6 d-none d-xl-block text-center"></div>
          </div>
        </div>
      </header>

      {/* Features section */}
      <section className="py-5" id="features">
        <div className="container px-5 my-5">
          <div className="row mb-5">
            <div className="col-lg-12 mb-5 mb-lg-0">
              <h2 className="fw-thin mb-0">See what's in the pipeline.</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="card property-card h-100 shadow border-0">
                <div className="img-wrap img-anim">
                  <img className="card-img-top" src={property3} alt="Deal 1" />
                </div>
                <div className="card-body p-4">
                  <div className="badge rounded-pill mb-2">Deal</div>
                  <a
                    className="text-decoration-none link-dark stretched-link"
                    href="#!"
                  >
                    <h5 className="card-title mb-3">Deal 1</h5>
                  </a>
                  <p className="card-text mb-0">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="card property-card h-100 shadow border-0">
                <div className="img-wrap img-anim">
                  <img className="card-img-top" src={property1} alt="Deal 2" />
                </div>
                <div className="card-body p-4">
                  <div className="badge rounded-pill mb-2">Deal</div>
                  <a
                    className="text-decoration-none link-dark stretched-link"
                    href="#!"
                  >
                    <h5 className="card-title mb-3">Deal 2</h5>
                  </a>
                  <p className="card-text mb-0">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="card property-card h-100 shadow border-0">
                <div className="img-wrap img-anim">
                  <img className="card-img-top" src={property4} alt="Deal 3" />
                </div>
                <div className="card-body p-4">
                  <div className="badge rounded-pill mb-2">Deal</div>
                  <a
                    className="text-decoration-none link-dark stretched-link"
                    href="#!"
                  >
                    <h5 className="card-title mb-3">Deal 3</h5>
                  </a>
                  <p className="card-text mb-0">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="card property-card h-100 shadow border-0">
                <div className="img-wrap img-anim">
                  <img className="card-img-top" src={property5} alt="Deal 4" />
                </div>
                <div className="card-body p-4">
                  <div className="badge rounded-pill mb-2">Deal</div>
                  <a
                    className="text-decoration-none link-dark stretched-link"
                    href="#!"
                  >
                    <h5 className="card-title mb-3">Deal 4</h5>
                  </a>
                  <p className="card-text mb-0">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 bg-light">
        <div className="container px-5 my-5">
          <div className="row mb-5">
            <div className="col-lg-12 mb-5 mb-lg-0">
              <h2 className="fw-thin mb-0">Check out our case studies</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="card case-card h-100 border-0">
                <img
                  className="card-img-top"
                  src={property2}
                  alt="Case Study 1"
                />
                <div className="card-body p-4">
                  <a
                    className="text-decoration-none link-dark stretched-link"
                    href="#!"
                  >
                    <h5 className="card-title mb-3">Case Study 1</h5>
                  </a>
                  <p className="card-text mb-0">Case Study Description</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card case-card h-100 border-0">
                <img
                  className="card-img-top"
                  src={property6}
                  alt="Case Study 2"
                />
                <div className="card-body p-4">
                  <a
                    className="text-decoration-none link-dark stretched-link"
                    href="#!"
                  >
                    <h5 className="card-title mb-3">Case Study 2</h5>
                  </a>
                  <p className="card-text mb-0">Case Study Description</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card case-card h-100 border-0">
                <img
                  className="card-img-top"
                  src={property1}
                  alt="Case Study 3"
                />
                <div className="card-body p-4">
                  <a
                    className="text-decoration-none link-dark stretched-link"
                    href="#!"
                  >
                    <h5 className="card-title mb-3">Case Study 3</h5>
                  </a>
                  <p className="card-text mb-0">Case Study Description</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Featured Services Section */}
      <ServicesSections/>


      {/* Footer */}
      {/* <footer className="bg-light py-5">
        <div className="container px-5">
          <div className="row">
            <div className="col-lg-6 mb-4 mb-lg-0">
              <h5 className="fw-bold">VestifyHub</h5>
              <p className="small">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla condimentum quam a finibus tincidunt.</p>
              <ul className="list-unstyled">
                <li><a className="link-dark" href="#!">Privacy Policy</a></li>
                <li><a className="link-dark" href="#!">Terms of Service</a></li>
              </ul>
            </div>
            <div className="col-lg-6">
              <h5 className="fw-bold">Contact Us</h5>
              <p className="small">Email: support@vestifyhub.com</p>
              <p className="small">Phone: +123 456 7890</p>
            </div>
          </div>
        </div>
      </footer> */}
    </main>
  );
}

export default HomePage;
