import React from 'react';
import { FaCheck } from "react-icons/fa6";



const Offerings = () => {
  return (
    <div className="d-flex flex-column">
      <main className="flex-shrink-0">
        {/* Header */}
        <header
          className="bg-dark page-header has-bg bg-fixed"
          style={{ backgroundImage: 'url(img/home-hero.jpg)' }}
        >
          <div className="section-overlay"></div>
          <div className="container px-5">
            <div className="row gx-5 align-items-center justify-content-center">
              <div className="col-lg-10">
                <div className="my-5 text-center">
                  <h1 className="page-title display-5 fw-thin text-white">Our Offerings</h1>
                  <p className="page-description lead fw-normal text-white">
                    We prioritize understanding your goals, preferences, and risk appetite to tailor investment solutions.
                  </p>
                </div>
              </div>
              <div className="col-xl-5 col-xxl-6 d-none d-xl-block text-center"></div>
            </div>
          </div>
        </header>

        {/* Features Section 1 */}
        <section className="py-4">
          <div className="container px-5 my-5">
            <div className="row mb-5">
              <div className="col-lg-12 mb-5 mb-lg-0">
                <h2 className="fw-thin mb-0">How we do it</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-10">
                <p className="lead">
                  We prioritize understanding your goals, preferences, and risk appetite to tailor investment solutions.
                </p>
                <ul className="page-list">
                  <li>Choose from a range of properties and products based on your investment plan</li>
                  <li>Receive regular cash returns and expand your portfolio across multiple assets</li>
                  <li>Benefit from our expertise in property refurbishment and market insights</li>
                  <li>Explore investment opportunities in the vibrant city of Manchester and its surroundings</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* Features Section 2 */}
        <section className="py-4 bg-light">
          <div className="container px-5 my-5">
            <div className="row mb-5">
              <div className="col-lg-12 mb-5 mb-lg-0">
                <h2 className="fw-thin mb-0">Where We Invest</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-10">
                <p className="lead">
                  Explore investment opportunities in the vibrant city of Manchester and its surroundings. With attractive rental yields and promising prospects for capital appreciation, Manchester is an ideal destination for property investment.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Offerings;
