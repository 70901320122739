import React from 'react';
import homeHeroImg from '../img/home-hero.jpg';
import property1 from '../img/property-1.jpg';
import property3 from '../img/property-3.jpg';
import property4 from '../img/property-4.jpg';
import property5 from '../img/property-5.jpg';
import ServicesSections from './ServicesSection';

const DealsInDevelopment = () => {
  return (
    <>
      <header className="bg-dark page-header has-bg bg-fixed" style={{ backgroundImage: `url(${homeHeroImg})` }}>
        <div className="section-overlay"></div>
        <div className="container px-5">
          <div className="row gx-5 align-items-center justify-content-center">
            <div className="col-lg-10">
              <div className="my-5 text-center">
                <h1 className="page-title display-5 fw-thin text-white">Deals in Development</h1>
                <p className="page-description lead fw-normal text-white">Find a deal, Acquire and Earn!</p>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section className="py-5" id="features">
        <div className="container px-5 my-5">
          <div className="row mb-5">
            <div className="col-lg-12 mb-5 mb-lg-0">
              <h2 className="fw-thin mb-0">See Deals in Development.</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="card property-card h-100 shadow border-0">
                <div className="img-wrap img-anim">
                  <img className="card-img-top" src={property3} alt="Deal 1" />
                </div>
                <div className="card-body p-4">
                  <div className="badge rounded-pill mb-2">Deal</div>
                  <a className="text-decoration-none link-dark stretched-link" href="#!">
                    <h5 className="card-title mb-3">Deal 1</h5>
                  </a>
                  <p className="card-text mb-0">
                    Some quick example text to build on the card title and make up the bulk of the card's content.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="card property-card h-100 shadow border-0">
                <div className="img-wrap img-anim">
                  <img className="card-img-top" src={property1} alt="Deal 2" />
                </div>
                <div className="card-body p-4">
                  <div className="badge rounded-pill mb-2">Deal</div>
                  <a className="text-decoration-none link-dark stretched-link" href="#!">
                    <h5 className="card-title mb-3">Deal 2</h5>
                  </a>
                  <p className="card-text mb-0">
                    Some quick example text to build on the card title and make up the bulk of the card's content.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="card property-card h-100 shadow border-0">
                <div className="img-wrap img-anim">
                  <img className="card-img-top" src={property4} alt="Deal 3" />
                </div>
                <div className="card-body p-4">
                  <div className="badge rounded-pill mb-2">Deal</div>
                  <a className="text-decoration-none link-dark stretched-link" href="#!">
                    <h5 className="card-title mb-3">Deal 3</h5>
                  </a>
                  <p className="card-text mb-0">
                    Some quick example text to build on the card title and make up the bulk of the card's content.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="card property-card h-100 shadow border-0">
                <div className="img-wrap img-anim">
                  <img className="card-img-top" src={property5} alt="Deal 4" />
                </div>
                <div className="card-body p-4">
                  <div className="badge rounded-pill mb-2">Deal</div>
                  <a className="text-decoration-none link-dark stretched-link" href="#!">
                    <h5 className="card-title mb-3">Deal 4</h5>
                  </a>
                  <p className="card-text mb-0">
                    Some quick example text to build on the card title and make up the bulk of the card's content.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ServicesSections/>
    </>
  );
};

export default DealsInDevelopment;
