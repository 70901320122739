import React from 'react';
import './App.css'; // Your CSS
import NavBar from './components/NavBar'; 
import HomePage from './components/HomePage';
import AboutUs from './components/AboutUs';
import OurOffering from './components/OurOffering';
import OurPrinciple from './components/OurPrinciple';
import HowItWorks from './components/HowItWorks';
import Invest from './components/Invest';
import Manage from './components/Manage';
import Pipeline from './components/Pipeline';
import Development from './components/Development';
import Cashflow from './components/CashFlow';
import Footer from './components/Footer';
import SignUp from './components/SignUp';
import Login from './components/LogIn';
import { Routes, Route, useLocation } from 'react-router-dom'; // Added useLocation hook

function App() {
  const location = useLocation(); // Get current location

  const hideNavFooter = location.pathname === '/login' || location.pathname === '/sign_up';

  return (
    <div className='App'>
      {/* Conditionally render NavBar based on route */}
      {!hideNavFooter && <NavBar />}
      
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="about_us" element={<AboutUs />} />
        <Route path="our_offering" element={<OurOffering />} />
        <Route path="our_principle" element={<OurPrinciple />} />
        <Route path="how_it_works" element={<HowItWorks />} />
        <Route path="invest" element={<Invest />} />
        <Route path="manage" element={<Manage />} />
        <Route path="pipeline" element={<Pipeline />} />
        <Route path="development" element={<Development />} />
        <Route path="cash_flow" element={<Cashflow />} />
        <Route path="sign_up" element={<SignUp />} />
        <Route path="login" element={<Login />} />
        
        {/* Add more routes as needed */}
      </Routes>

      {/* Conditionally render Footer based on route */}
      {!hideNavFooter && <Footer />}
    </div>
  );
}

export default App;
