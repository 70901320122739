import React from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import logo from "../img/logo.png"; 



function NavBar() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="container px-5">
        <div className="navbar-brand">
          <a href="/">
            <div className="logo">
              <img src={logo} alt="VestifyHub" />
            </div>
          </a>
        </div>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#!"
                id="deals"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                About <IoIosArrowDown />
              </a>
              <ul className="dropdown-menu border-0 shadow bsb-zoomIn" aria-labelledby="deals">
                <li>
                  <a className="dropdown-item" href="about_us">
                    Our Story
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="our_offering">
                    Our Offering
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="our_principle">
                    Our Principles
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="how_it_works">
                    How Vestify Works
                  </a>
                </li>
              </ul>
            </li>

            <li className="nav-item">
              <a className="nav-link" href="invest">
                Invest
              </a>
            </li>

            <li className="nav-item">
              <a className="nav-link" href="manage">
                Manage
              </a>
            </li>

            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#!"
                id="deals"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Deals  <IoIosArrowDown />
              </a>
              <ul className="dropdown-menu border-0 shadow bsb-zoomIn" aria-labelledby="deals">
                <li>
                  <a className="dropdown-item" href="pipeline">
                    Pipeline
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="development">
                    In Development
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="cash_flow">
                    Cash Flowing
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div className="nav-button-wrap">
          <a className="btn btn-primary-alt btn-md px-4 me-sm-3" href="sign_up">
            Sign Up
          </a>
          <a className="btn btn-primary-line btn-md px-4 me-sm-3" href="login">
            Login
          </a>
        </div>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>
    </nav>
  );
}

export default NavBar;
