import React from 'react';
import ServicesSections from './ServicesSection';
import homeHeroImage from "../img/home-hero.jpg";

const ManageDeals = () => {
  return (
    <>
      {/* Header */}
      <header
        className="bg-dark page-header has-bg bg-fixed"
        style={{ backgroundImage: `url(${homeHeroImage})` }}
      >
        <div className="section-overlay"></div>
        <div className="container px-5">
          <div className="row gx-5 align-items-center justify-content-center">
            <div className="col-lg-10">
              <div className="my-5 text-center">
                <h1 className="page-title display-5 fw-thin text-white">Manage</h1>
                <p className="page-description lead fw-normal text-white">Manage Your Deals</p>
              </div>
            </div>
            <div className="col-xl-5 col-xxl-6 d-none d-xl-block text-center"></div>
          </div>
        </div>
      </header>

      {/* Features section */}
      <section className="py-4" id="features">
        <div className="container px-5 my-5">
          <div className="row mb-5">
            <div className="col-lg-12 mb-5 mb-lg-0">
              <h2 className="fw-thin mb-0">Manage Deals</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-10">
              <p className="lead">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam at libero ut odio
                ultricies gravida. Pellentesque ut tellus justo. Fusce sit amet ullamcorper tellus, at
                imperdiet enim. Fusce lacinia est pellentesque sagittis volutpat. Proin finibus dictum
                leo at congue. Quisque cursus volutpat est elementum facilisis. Morbi massa eros,
                dictum a ante vitae, tristique congue nibh. Curabitur feugiat eleifend tortor, at
                scelerisque metus pulvinar vel. Interdum et malesuada fames ac ante ipsum primis in
                faucibus.
              </p>
              <div className="d-flex gap-3 d-sm-flex justify-content-start mt-5">
                <a className="btn btn-primary-alt btn-md px-4 me-sm-3" href="#features">
                  Manage Deals
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ServicesSections/>
    </>
  );
};

export default ManageDeals;
