import React from 'react';
import { BiMap, BiPhone } from 'react-icons/bi'; // Using BiMap and BiPhone as replacements

const Footer = () => {
  return (
    <footer>
      <div className="footer-top bg-dark py-5">
        <div className="container px-5">
          <div className="row">
            <div className="col-lg-3 col-6 footer-links">
              <h4>Company</h4>
              <ul>
                <li><a href="our-story.html">Our Story</a></li>
                <li><a href="our-offerings.html">Our Offerings</a></li>
                <li><a href="#">Our Principles</a></li>
                <li><a href="#">Partner With Us</a></li>
                <li><a href="#">Career</a></li>
              </ul>
            </div>

            <div className="col-lg-3 col-6 footer-links">
              <h4>Products</h4>
              <ul>
                <li><a href="#">Invest</a></li>
                <li><a href="#">Manage</a></li>
                <li><a href="#">Pipeline</a></li>
                <li><a href="#">Renovate</a></li>
                <li><a href="#">Brokerage</a></li>
              </ul>
            </div>

            <div className="col-lg-2 col-6 footer-links">
              <h4>Sign Up</h4>
              <ul>
                <li><a href="#">Investor</a></li>
                <li><a href="#">Tenant</a></li>
                <li><a href="#">Landlord</a></li>
                <li><a href="#">Vendor</a></li>
                <li><a href="#">Agency</a></li>
              </ul>
            </div>

            <div className="col-lg-4 col-12 footer-contacts text-right justify-right">
              <h4>Contacts</h4>
              <p>
                <span className="icon"><BiMap /></span>
                Address Street<br />
                New York, NY Zip<br />
                United States
              </p>
              <p className="mt-4">
                <span className="icon"><BiPhone /></span>
                +1 5589 55488 55
              </p>
              <p>
                <span className="icon"><BiMap /></span>
                info@example.com
              </p>
            </div>

          </div>
        </div>
      </div>
      <div className="footer-copyrigt bg-dark">
        <div className="container px-5 py-3">
          <div className="row align-items-center justify-content-between flex-column flex-sm-row">
            <div className="col-auto">
              <div className="small m-0 text-white">
                Copyright &copy; VestifyHub 2024. All Rights Reserved
              </div>
            </div>
            <div className="col-auto">
              <a className="link-light small" href="#!">Privacy</a>
              <span className="text-white mx-1">&middot;</span>
              <a className="link-light small" href="#!">Terms</a>
              <span className="text-white mx-1">&middot;</span>
              <a className="link-light small" href="#!">Contact</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
