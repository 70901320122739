import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css'; // For Bootstrap Icons
import 'aos/dist/aos.css'; // If you are using AOS animations
import AOS from 'aos'; // Import AOS library

const ServicesSections = () => {
  // Initialize AOS for animations
  React.useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
   
    

    

      {/* Featured Services Section */}
      <section id="featured-services" className="featured-services py-5 bg-light">
        <div className="container px-5 my-5">
          <div className="row gy-4">
            {/* Service Item 1 */}
            <div
              className="col-xl-4 col-lg-6"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="service-item d-flex">
                <div className="icon flex-shrink-0">
                  <i className="bi bi-search"></i>
                </div>
                <div>
                  <h4 className="title">
                    <a href="#" className="stretched-link">
                      Find
                    </a>
                  </h4>
                  <p className="description">
                    Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi
                  </p>
                </div>
              </div>
            </div>

            {/* Service Item 2 */}
            <div
              className="col-xl-4 col-lg-6"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="service-item d-flex">
                <div className="icon flex-shrink-0">
                  <i className="bi bi-gem"></i>
                </div>
                <div>
                  <h4 className="title">
                    <a href="#" className="stretched-link">
                      Acquire
                    </a>
                  </h4>
                  <p className="description">
                    Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip exa
                  </p>
                </div>
              </div>
            </div>

            {/* Service Item 3 */}
            <div
              className="col-xl-4 col-lg-6"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="service-item d-flex">
                <div className="icon flex-shrink-0">
                  <i className="bi bi-wallet2"></i>
                </div>
                <div>
                  <h4 className="title">
                    <a href="#" className="stretched-link">
                      Earn
                    </a>
                  </h4>
                  <p className="description">
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesSections;
