import React, { useEffect } from 'react';
import 'aos/dist/aos.css'; // AOS animations
import AOS from 'aos';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Font Awesome
import '@fortawesome/fontawesome-free/css/all.min.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import ServicesSections from './ServicesSection';


const HowVestifyWorks = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      {/* Header */}
      <header
        className="bg-dark page-header has-bg bg-fixed"
        style={{ backgroundImage: 'url(img/home-hero.jpg)' }}
      >
        <div className="section-overlay"></div>
        <div className="container px-5">
          <div className="row gx-5 align-items-center justify-content-center">
            <div className="col-lg-10">
              <div className="my-5 text-center">
                <h1 className="page-title display-5 fw-thin text-white">How Vestify Works</h1>
                <p className="page-description lead fw-normal text-white">
                  Find a deal, Acquire and Earn!
                </p>
              </div>
            </div>
            <div className="col-xl-5 col-xxl-6 d-none d-xl-block text-center"></div>
          </div>
        </div>
      </header>

      {/* Features Section */}
      <section className="py-4 bg-light" id="features">
        <div className="container px-5 my-5">
          <div className="row mb-5">
            <div className="col-lg-12 mb-5 mb-lg-0">
              <h2 className="fw-thin mb-0">Unlock True Investment Potential</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-10">
              <p className="lead">
                Discover the lucrative world of property investment in the United Kingdom. Whether
                you're a novice or a seasoned investor, our fully-managed investment options offer
                substantial benefits and long-term growth potential.
              </p>
              <p>
                We prioritize understanding your goals, preferences, and risk appetite to tailor
                investment solutions.
              </p>
              <ul className="page-list">
                <li>Choose from a range of properties and products based on your investment plan.</li>
                <li>Receive regular cash returns and expand your portfolio across multiple assets.</li>
                <li>Benefit from our expertise in property refurbishment and market insights.</li>
                <li>We can share our property knowledge with you.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* How We Do It Section */}
      <section className="py-5">
        <div className="container px-5 my-5">
          <div className="text-left mb-5">
            <h1 className="fw-thin">How We Do It</h1>
            <p className="lead fw-thin text-muted mb-0">
              We offer 3 routes (3-S wealth creation) to create long-term wealth through property
              investment meaning you start creating your wealth plan today.
            </p>
          </div>
          <div className="row gx-5">
            <div className="col-xl-8">
              <div className="accordion vf-accordion mb-5 mb-xl-0" id="accordionExample2">
                {/* Starter */}
                <div className="accordion-item">
                  <h3 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne2"
                      aria-expanded="true"
                      aria-controls="collapseOne2"
                    >
                      Starter
                    </button>
                  </h3>
                  <div
                    className="accordion-collapse collapse show"
                    id="collapseOne2"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      <p>
                        Ideal for those looking to dip their toes into property investment, offering
                        fixed cash returns over a specified period with minimal risk.
                      </p>
                      <ul className="page-list">
                        <li>Strong Asset Class: Property investments have higher returns.</li>
                        <li>Biannual Interest Payments: Receive payments monthly, quarterly.</li>
                        <li>Bond Term: 12-month minimum term.</li>
                        <li>
                          Equity Conversion: Option to convert to equity in first-string 'slice' or
                          'starter' products.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                {/* Slice */}
                <div className="accordion-item">
                  <h3 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo2"
                      aria-expanded="false"
                      aria-controls="collapseTwo2"
                    >
                      Slice
                    </button>
                  </h3>
                  <div
                    className="accordion-collapse collapse"
                    id="collapseTwo2"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      <p>
                        For investors seeking diversified income streams and future value
                        appreciation, either through individual properties or collective investments.
                      </p>
                      <ul className="page-list">
                        <li>Easy Entry: Buy into an existing cash-flowing investment property.</li>
                        <li>Earn Returns From Day 1: Share of future value appreciation.</li>
                        <li>Earn Monthly Rental Income: Receive payments monthly, quarterly.</li>
                      </ul>
                    </div>
                  </div>
                </div>

                {/* Stake */}
                <div className="accordion-item">
                  <h3 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree2"
                      aria-expanded="false"
                      aria-controls="collapseThree2"
                    >
                      Stake
                    </button>
                  </h3>
                  <div
                    className="accordion-collapse collapse"
                    id="collapseThree2"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      <p>
                        Designed for investors with capital but limited time or expertise, providing
                        a hassle-free portfolio-building service with promising returns.
                      </p>
                      <ul className="page-list">
                        <li>
                          Find Your Freedom: Hands-free portfolio building with strong returns.
                        </li>
                        <li>
                          Hassle-Free: Everything is managed, no landlord call-outs, no tenant
                          issues.
                        </li>
                        <li>
                          Cash Flow & Appreciation: Steady cash flow and long-term value increase.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Contact Section */}
            <div className="col-xl-4">
              <div className="card border-0 bg-light">
                <div className="card-body p-4 py-lg-5">
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="text-center">
                      <div className="h6 fw-thin">Have more questions?</div>
                      <p className="text-muted mb-4">
                        Contact us at
                        <br />
                        <a href="mailto:support@domain.com">support@domain.com</a>
                      </p>
                      <div className="h6 fw-thin">Follow us</div>
                      <a className="fs-5 px-2 link-dark" href="#!">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a className="fs-5 px-2 link-dark" href="#!">
                        <i className="fab fa-facebook"></i>
                      </a>
                      <a className="fs-5 px-2 link-dark" href="#!">
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a className="fs-5 px-2 link-dark" href="#!">
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Featured Services Section */}
      <ServicesSections/>

    </>
  );
};

export default HowVestifyWorks;
