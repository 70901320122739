import React from 'react';

const SignIn = () => {
  return (
    <section className="py-5 bg-light" id="manage">
      <div className="container px-5 my-5">
        <div className="row mb-5"></div>
        <div className="row">
          <div className="col-lg-3"></div>

          <div id="investment" className="col-lg-6">
            <div className="col-lg-12 mb-5 mb-lg-0 text-center">
              <h2 className="fw-thin mb-0">Sign In</h2>
              <p className="fw-thin">Sign in to your account to manage your investment.</p>
            </div>
            <div className="signup-wrap">
              <form id="contactForm" data-sb-form-api-token="API_TOKEN">
                {/* Username/Email input */}
                <div className="form-floating mb-4">
                  <input
                    className="form-control"
                    id="username"
                    type="text"
                    placeholder="Enter your username or email address..."
                    required
                  />
                  <label htmlFor="username">Username/Email Address</label>
                  <div className="invalid-feedback">Username or Email is required.</div>
                </div>
                {/* Password input */}
                <div className="form-floating mb-4">
                  <input
                    className="form-control form-password"
                    id="password"
                    type="password"
                    placeholder="Enter your password"
                    required
                  />
                  <label htmlFor="password">Password</label>
                  <div className="invalid-feedback">Please type your password</div>
                </div>

                {/* Submit success message */}
                <div className="d-none" id="submitSuccessMessage">
                  <div className="text-center mb-3">
                    <div className="fw-bolder">Form submission successful!</div>
                    To activate this form, sign up at
                    <br />
                    <a href="https://startbootstrap.com/solution/contact-forms">
                      https://startbootstrap.com/solution/contact-forms
                    </a>
                  </div>
                </div>
                {/* Submit error message */}
                <div className="d-none" id="submitErrorMessage">
                  <div className="text-center text-danger mb-3">Error sending message!</div>
                </div>

                {/* Submit Button */}
                <div className="d-grid button-wrap-center mb-3">
                  <button className="btn btn-submit btn-lg" id="submitButton" type="submit">
                    Sign In
                  </button>
                </div>

                <div className="d-flex justify-content-between mb-3">
                  <label htmlFor="remember">
                    <input type="checkbox" name="remember" defaultChecked />
                    &nbsp; Remember me
                  </label>
                  <a href="#">Forgot Password?</a>
                </div>

                <div className="d-flex justify-content-center mb-3">
                  Not yet a member?&nbsp;<a href="#">Sign up</a>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
    </section>
  );
};

export default SignIn;
