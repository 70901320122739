import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css'; // For Bootstrap Icons
import 'aos/dist/aos.css'; // If you are using AOS animations
import AOS from 'aos'; // Import AOS library
import ServicesSections from './ServicesSection';
const OurPrinciple = () => {
  // Initialize AOS for animations
  React.useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      {/* Header */}
      <header
        className="bg-dark page-header has-bg bg-fixed"
        style={{ backgroundImage: 'url(img/home-hero.jpg)' }}
      >
        <div className="section-overlay"></div>
        <div className="container px-5">
          <div className="row gx-5 align-items-center justify-content-center">
            <div className="col-lg-10">
              <div className="my-5 text-center">
                <h1 className="page-title display-5 fw-thin text-white">Our Principles</h1>
                <p className="page-description lead fw-normal text-white">
                  Learn more about us, what we do, and how we work.
                </p>
              </div>
            </div>
            <div className="col-xl-5 col-xxl-6 d-none d-xl-block text-center"></div>
          </div>
        </div>
      </header>

      {/* Features Section */}
      <section className="py-4" id="features">
        <div className="container px-5 my-5">
          <div className="row mb-5">
            <div className="col-lg-12 mb-5 mb-lg-0">
              <h2 className="fw-thin mb-0">Our Principles</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-10">
              <p className="lead">
                At Vestify Hub, we are more than just consultants. We are visionaries committed to
                building a better future through real estate. With a focus on acquiring, transforming,
                and managing residential assets, we strive to create value for our clients, tenants, and
                communities.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Featured Services Section */}
    <ServicesSections/>
    </>
  );
};

export default OurPrinciple;
