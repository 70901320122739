import React from 'react';

const SignUp = () => {
  return (
    <section className="py-5 bg-light" id="manage">
      <div className="container px-5 my-5">
        <div className="row mb-5"></div>
        <div className="row">
          <div className="col-lg-3"></div>

          <div id="investment" className="col-lg-6">
            <div className="col-lg-12 mb-5 mb-lg-0 text-center">
              <h2 className="fw-thin mb-0">Sign Up</h2>
              <p className="fw-thin">Start your investment. Create an account.</p>
            </div>
            <div className="signup-wrap">
              <form id="contactForm" data-sb-form-api-token="API_TOKEN">
                {/* Name input */}
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    id="name"
                    type="text"
                    placeholder="Enter your name..."
                    required
                  />
                  <label htmlFor="name">Full name</label>
                  <div className="invalid-feedback">A name is required.</div>
                </div>
                {/* Username input */}
                <div className="form-floating mb-4">
                  <input
                    className="form-control"
                    id="username"
                    type="text"
                    placeholder="Enter your username..."
                    required
                  />
                  <label htmlFor="username">Username</label>
                  <div className="invalid-feedback">Username or Email is required.</div>
                </div>
                {/* Email address input */}
                <div className="form-floating mb-4">
                  <input
                    className="form-control"
                    id="email"
                    type="email"
                    placeholder="Enter your email..."
                    required
                  />
                  <label htmlFor="email">Email Address</label>
                  <div className="invalid-feedback">Please type your email</div>
                  <div className="invalid-feedback">Email is not valid.</div>
                </div>
                {/* Confirm Email address input */}
                <div className="form-floating mb-4">
                  <input
                    className="form-control"
                    id="confirmEmail"
                    type="email"
                    placeholder="Confirm your email..."
                    required
                  />
                  <label htmlFor="confirmEmail">Confirm Email Address</label>
                  <div className="invalid-feedback">Please confirm your email</div>
                  <div className="invalid-feedback">Email does not match.</div>
                </div>

                {/* Submit success message */}
                <div className="d-none" id="submitSuccessMessage">
                  <div className="text-center mb-3">
                    <div className="fw-bolder">Form submission successful!</div>
                    To activate this form, sign up at
                    <br />
                    <a href="https://startbootstrap.com/solution/contact-forms">
                      https://startbootstrap.com/solution/contact-forms
                    </a>
                  </div>
                </div>
                {/* Submit error message */}
                <div className="d-none" id="submitErrorMessage">
                  <div className="text-center text-danger mb-3">
                    Error sending message!
                  </div>
                </div>

                {/* Submit Button */}
                <div className="d-grid button-wrap-center mb-3">
                  <button className="btn btn-submit btn-lg" id="submitButton" type="submit">
                    Sign Up
                  </button>
                </div>

                <div className="d-flex justify-content-center mb-3">
                  Already registered?&nbsp;<a href="#">Sign In</a>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
    </section>
  );
};

export default SignUp;
