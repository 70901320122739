import React from "react";
import homeHeroImage from "../img/home-hero.jpg";
import property1 from "../img/property-1.jpg";
import property2 from "../img/property-2.jpg";
import property6 from "../img/property-6.jpg";
import ServicesSections from "./ServicesSection";

const Home = () => {
  return (
    <>
      {/* Header Section */}
      <header
        className="bg-dark home-hero has-bg bg-fixed"
        style={{ backgroundImage: `url(${homeHeroImage})` }}
      >
        <div className="section-overlay"></div>
        <div className="container px-5">
          <div className="row gx-5 align-items-center justify-content-center">
            <div className="col-lg-8 col-xl-7 col-xxl-6">
              <div className="my-5 text-center text-xl-start">
                <h1 className="hero-title display-5 fw-thin text-white">
                  Start your investment with
                  <br />
                  Vestify<span className="fw-bolder">Hub</span>
                </h1>
                <p className="hero-description lead fw-normal text-white">
                  Find a deal, Acquire and Earn!
                </p>
                <div className="d-flex gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start mt-5">
                  <a
                    className="btn btn-primary-alt btn-md px-4 me-sm-3"
                    href="#invest"
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-5 col-xxl-6 d-none d-xl-block text-center"></div>
          </div>
        </div>
      </header>

      {/* Features Section */}
      <section className="py-4" id="invest">
        <div className="container px-5 my-5">
          <div className="row mb-5">
            <div className="col-lg-12 mb-5 mb-lg-0">
              <h2 className="fw-thin mb-0">Start Your Investment</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-10">
              <p className="lead">
                Discover the lucrative world of property investment in the
                United Kingdom.
              </p>
              <p>
                Whether you're a novice or a seasoned investor, our
                fully-managed investment options offer substantial benefits and
                long-term growth potential. We prioritize understanding your
                goals, preferences, and risk appetite to tailor investment
                solutions.
              </p>
              <div className="d-flex gap-3 d-sm-flex justify-content-start mt-5">
                <a
                  className="btn btn-primary-alt btn-md px-4 me-sm-3"
                  href="#features"
                >
                  Get Started
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Case Studies Section */}
      <section className="py-5 bg-light">
        <div className="container px-5 my-5">
          <div className="row mb-5">
            <div className="col-lg-12 mb-5 mb-lg-0">
              <h2 className="fw-thin mb-0">Check out our case studies</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="card case-card h-100 border-0">
                <img
                  className="card-img-top"
                  src={property2}
                  alt="Property 2"
                />
                <div className="card-body p-4">
                  <a
                    className="text-decoration-none link-dark stretched-link"
                    href="#!"
                  >
                    <h5 className="card-title mb-3">Case Study 1</h5>
                  </a>
                  <p className="card-text mb-0">Case Study Description</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card case-card h-100 border-0">
                <img
                  className="card-img-top"
                  src={property6}
                  alt="Property 6"
                />
                <div className="card-body p-4">
                  <a
                    className="text-decoration-none link-dark stretched-link"
                    href="#!"
                  >
                    <h5 className="card-title mb-3">Case Study 2</h5>
                  </a>
                  <p className="card-text mb-0">Case Study Description</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card case-card h-100 border-0">
                <img
                  className="card-img-top"
                  src={property1}
                  alt="Property 1"
                />
                <div className="card-body p-4">
                  <a
                    className="text-decoration-none link-dark stretched-link"
                    href="#!"
                  >
                    <h5 className="card-title mb-3">Case Study 3</h5>
                  </a>
                  <p className="card-text mb-0">Case Study Description</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Featured Services Section */}
      <ServicesSections />
    </>
  );
};

export default Home;
